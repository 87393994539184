import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _afc8c48a = () => interopDefault(import('../pages/aovivo.vue' /* webpackChunkName: "pages/aovivo" */))
const _f7fabf34 = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _78be8adc = () => interopDefault(import('../pages/canais.vue' /* webpackChunkName: "pages/canais" */))
const _23e87d8f = () => interopDefault(import('../pages/carteiras.vue' /* webpackChunkName: "pages/carteiras" */))
const _5c9164e7 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _c1d10708 = () => interopDefault(import('../pages/disclaimer.vue' /* webpackChunkName: "pages/disclaimer" */))
const _1b0d0482 = () => interopDefault(import('../pages/feed.vue' /* webpackChunkName: "pages/feed" */))
const _211f28c0 = () => interopDefault(import('../pages/flusher.vue' /* webpackChunkName: "pages/flusher" */))
const _541e884e = () => interopDefault(import('../pages/inter/index.vue' /* webpackChunkName: "pages/inter/index" */))
const _9ba7c92c = () => interopDefault(import('../pages/invite.vue' /* webpackChunkName: "pages/invite" */))
const _75644f06 = () => interopDefault(import('../pages/migration.vue' /* webpackChunkName: "pages/migration" */))
const _1469e80e = () => interopDefault(import('../pages/minha-assinatura.vue' /* webpackChunkName: "pages/minha-assinatura" */))
const _249ce132 = () => interopDefault(import('../pages/pdt/index.vue' /* webpackChunkName: "pages/pdt/index" */))
const _7567c797 = () => interopDefault(import('../pages/politica-de-privacidade.vue' /* webpackChunkName: "pages/politica-de-privacidade" */))
const _695c3512 = () => interopDefault(import('../pages/primeiros-passos.vue' /* webpackChunkName: "pages/primeiros-passos" */))
const _366794ba = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _4c6ee5d1 = () => interopDefault(import('../pages/seguindo.vue' /* webpackChunkName: "pages/seguindo" */))
const _2bbb1e91 = () => interopDefault(import('../pages/termos-de-uso.vue' /* webpackChunkName: "pages/termos-de-uso" */))
const _fb3ecf58 = () => interopDefault(import('../pages/termos-de-uso-parceiros.vue' /* webpackChunkName: "pages/termos-de-uso-parceiros" */))
const _7c04b4d0 = () => interopDefault(import('../pages/tests.vue' /* webpackChunkName: "pages/tests" */))
const _14092fa6 = () => interopDefault(import('../pages/w.vue' /* webpackChunkName: "pages/w" */))
const _43668f8b = () => interopDefault(import('../pages/admin/revenue.vue' /* webpackChunkName: "pages/admin/revenue" */))
const _3da1cd37 = () => interopDefault(import('../pages/admin/users.vue' /* webpackChunkName: "pages/admin/users" */))
const _9f84519a = () => interopDefault(import('../pages/admin/whatsapp.vue' /* webpackChunkName: "pages/admin/whatsapp" */))
const _b7c871f0 = () => interopDefault(import('../pages/inter/magic-login/index.vue' /* webpackChunkName: "pages/inter/magic-login/index" */))
const _614e8280 = () => interopDefault(import('../pages/partner/finantial.vue' /* webpackChunkName: "pages/partner/finantial" */))
const _e1970672 = () => interopDefault(import('../pages/partner/stats.vue' /* webpackChunkName: "pages/partner/stats" */))
const _fb205cac = () => interopDefault(import('../pages/pdt/aovivo.vue' /* webpackChunkName: "pages/pdt/aovivo" */))
const _dda261fe = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _804093a0 = () => interopDefault(import('../pages/inter/canal/_roomSlug/index.vue' /* webpackChunkName: "pages/inter/canal/_roomSlug/index" */))
const _3b0fdaba = () => interopDefault(import('../pages/inter/magic-login/_hash.vue' /* webpackChunkName: "pages/inter/magic-login/_hash" */))
const _4cefe562 = () => interopDefault(import('../pages/carteira/_portfolioSlug/index.vue' /* webpackChunkName: "pages/carteira/_portfolioSlug/index" */))
const _5c8206ee = () => interopDefault(import('../pages/convite/_invite.vue' /* webpackChunkName: "pages/convite/_invite" */))
const _9df1401e = () => interopDefault(import('../pages/token/_token.vue' /* webpackChunkName: "pages/token/_token" */))
const _6e631500 = () => interopDefault(import('../pages/carteira/_portfolioSlug/_tradeId.vue' /* webpackChunkName: "pages/carteira/_portfolioSlug/_tradeId" */))
const _62259694 = () => interopDefault(import('../pages/_parceiro/index.vue' /* webpackChunkName: "pages/_parceiro/index" */))
const _4af3715e = () => interopDefault(import('../pages/_parceiro/cupom.vue' /* webpackChunkName: "pages/_parceiro/cupom" */))
const _c63e8e8e = () => interopDefault(import('../pages/_parceiro/w.vue' /* webpackChunkName: "pages/_parceiro/w" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aovivo",
    component: _afc8c48a,
    name: "aovivo"
  }, {
    path: "/callback",
    component: _f7fabf34,
    name: "callback"
  }, {
    path: "/canais",
    component: _78be8adc,
    name: "canais"
  }, {
    path: "/carteiras",
    component: _23e87d8f,
    name: "carteiras"
  }, {
    path: "/checkout",
    component: _5c9164e7,
    name: "checkout"
  }, {
    path: "/disclaimer",
    component: _c1d10708,
    name: "disclaimer"
  }, {
    path: "/feed",
    component: _1b0d0482,
    name: "feed"
  }, {
    path: "/flusher",
    component: _211f28c0,
    name: "flusher"
  }, {
    path: "/inter",
    component: _541e884e,
    name: "inter"
  }, {
    path: "/invite",
    component: _9ba7c92c,
    name: "invite"
  }, {
    path: "/migration",
    component: _75644f06,
    name: "migration"
  }, {
    path: "/minha-assinatura",
    component: _1469e80e,
    name: "minha-assinatura"
  }, {
    path: "/pdt",
    component: _249ce132,
    name: "pdt"
  }, {
    path: "/politica-de-privacidade",
    component: _7567c797,
    name: "politica-de-privacidade"
  }, {
    path: "/primeiros-passos",
    component: _695c3512,
    name: "primeiros-passos"
  }, {
    path: "/reset-password",
    component: _366794ba,
    name: "reset-password"
  }, {
    path: "/seguindo",
    component: _4c6ee5d1,
    name: "seguindo"
  }, {
    path: "/termos-de-uso",
    component: _2bbb1e91,
    name: "termos-de-uso"
  }, {
    path: "/termos-de-uso-parceiros",
    component: _fb3ecf58,
    name: "termos-de-uso-parceiros"
  }, {
    path: "/tests",
    component: _7c04b4d0,
    name: "tests"
  }, {
    path: "/w",
    component: _14092fa6,
    name: "w"
  }, {
    path: "/admin/revenue",
    component: _43668f8b,
    name: "admin-revenue"
  }, {
    path: "/admin/users",
    component: _3da1cd37,
    name: "admin-users"
  }, {
    path: "/admin/whatsapp",
    component: _9f84519a,
    name: "admin-whatsapp"
  }, {
    path: "/inter/magic-login",
    component: _b7c871f0,
    name: "inter-magic-login"
  }, {
    path: "/partner/finantial",
    component: _614e8280,
    name: "partner-finantial"
  }, {
    path: "/partner/stats",
    component: _e1970672,
    name: "partner-stats"
  }, {
    path: "/pdt/aovivo",
    component: _fb205cac,
    name: "pdt-aovivo"
  }, {
    path: "/",
    component: _dda261fe,
    name: "index"
  }, {
    path: "/inter/canal/:roomSlug",
    component: _804093a0,
    name: "inter-canal-roomSlug"
  }, {
    path: "/inter/magic-login/:hash?",
    component: _3b0fdaba,
    name: "inter-magic-login-hash"
  }, {
    path: "/carteira/:portfolioSlug",
    component: _4cefe562,
    name: "carteira-portfolioSlug"
  }, {
    path: "/convite/:invite?",
    component: _5c8206ee,
    name: "convite-invite"
  }, {
    path: "/token/:token?",
    component: _9df1401e,
    name: "token-token"
  }, {
    path: "/carteira/:portfolioSlug?/:tradeId",
    component: _6e631500,
    name: "carteira-portfolioSlug-tradeId"
  }, {
    path: "/:parceiro",
    component: _62259694,
    name: "parceiro"
  }, {
    path: "/:parceiro/cupom",
    component: _4af3715e,
    name: "parceiro-cupom"
  }, {
    path: "/:parceiro/w",
    component: _c63e8e8e,
    name: "parceiro-w"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
