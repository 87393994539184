import Auth from './auth'

import './middleware'

// Active schemes
import scheme_003d9a64 from './schemes/local.js'
import scheme_23514a38 from './schemes/oauth2.js'

export default function (ctx, inject) {
  // Options
  const options = {"resetOnError":false,"scopeKey":"scope","rewriteRedirects":true,"fullPathRedirect":false,"watchLoggedIn":true,"redirect":{"login":"/","logout":"/","home":false,"callback":"/callback"},"vuex":{"namespace":"auth"},"cookie":{"prefix":"auth.","options":{"path":"/","maxAge":"7776000"}},"localStorage":{"prefix":"auth."},"token":{"prefix":"_token."},"refresh_token":{"prefix":"_refresh_token."},"defaultStrategy":"local"}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // local
  $auth.registerStrategy('local', new scheme_003d9a64($auth, {"endpoints":{"login":{"url":"/api/auth/local/login","method":"post","propertyName":"token"},"logout":{"url":"/api/auth/logout","method":"get"},"user":{"url":"/api/auth/user","method":"get","propertyName":false}},"tokenRequired":false,"tokenType":false,"_name":"local"}))

  // google
  $auth.registerStrategy('google', new scheme_23514a38($auth, {"client_id":"868258613797-u1vt36gg1oppjbc9cerh924ojq1m4gaf.apps.googleusercontent.com","access_type":"offline","response_type":"code","access_token_endpoint":"/api/auth/google/login","userinfo_endpoint":"/api/auth/user","_name":"google","authorization_endpoint":"https://accounts.google.com/o/oauth2/auth","scope":["openid","profile","email"]}))

  // facebook
  $auth.registerStrategy('facebook', new scheme_23514a38($auth, {"client_id":"282947349021906","access_type":"offline","response_type":"code","access_token_endpoint":"/api/auth/facebook/login","userinfo_endpoint":"/api/auth/user","_name":"facebook","authorization_endpoint":"https://facebook.com/v2.12/dialog/oauth","scope":["public_profile","email"]}))

  // migration
  $auth.registerStrategy('migration', new scheme_003d9a64($auth, {"endpoints":{"login":{"url":"/api/auth/migration","method":"post"},"logout":{"url":"/api/auth/logout","method":"get"},"user":{"url":"/api/auth/user","propertyName":false}},"tokenRequired":false,"tokenType":false,"_name":"migration"}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      console.error('[ERROR] [AUTH]', error)
    }
  })
}
